import React, { ReactElement } from "react"
import { Image } from "../../../types/images"
import MoreLink from "../../components/MoreLink"
import WorkDetail from "../../components/WorkDetail"

const imgs: Image[] = [
  {
    src: "MarrisMiller/iPhone-flat-mockup_t6qu9h.jpg",
    alt: "Our Work",
    id: "1",
    chrome: false,
  },
  {
    src: "MarrisMiller/wireframe-grid_ncnppj.jpg",
    alt: "Our Work",
    id: "2",
    chrome: false,
  },
  {
    src: "MarrisMiller/homepage_kxsypy.jpg",
    alt: "Our Work",
    id: "3",
    chrome: true,
  },
  {
    src: "MarrisMiller/our-work_kzdtd9.jpg",
    alt: "Our Work",
    id: "4",
    chrome: true,
  },
  {
    src: "MarrisMiller/screenshot-the-firm_gbhluk.jpg",
    alt: "Home",
    id: "5",
    chrome: true,
  },
]

export default function MarrisMiller(): ReactElement {
  return (
    <WorkDetail title="Marris Miller" images={imgs}>
      <p className="lead">
        The world of pension management can be confusing and difficult to
        understand. Marris + Miller aim to bridge the gap between what is
        happening and what is possible.
      </p>

      <p>
        When you mention pension management to people, you might be met with a
        blank stare because the subject itself is large and complex. Marris +
        Miller aim to change that. Proper pension management means peace of mind
        for employees and a healthy future for your business.
      </p>
      <p>
        Often times branding in the financial sector can be dry and boring by
        relying on whats safe and removing whats really important, the human
        being. The design of the new site for Marris + Miller needed to
        communicate that pensions don&apos;t have to be clouded in complexity.
        That the information you need is accessible and can be distilled so that
        it&apos;s easily understood. With a proper plan you can ensure both your
        business and employees are safe and secure.
      </p>

      <MoreLink to="https://www.marrismiller.com/" />
    </WorkDetail>
  )
}
